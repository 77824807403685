import React from "react"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image/withIEPolyfill"

export const renderImage = (image, classes) => {
  // Checks image exists, before trying to find Sharp image
  if (!image) return false
  const { fluid } = (image.localFile || {}).childImageSharp || {}

  // Returns gatsby-image component with fluid Sharp image and classes
  return (
    <>
      {fluid ? (
        <Img
          placeholderStyle={{ opacity: 0 }}
          fluid={fluid}
          className={`${classes} pointer-events-none`}
          alt={image.altText || ""}
        />
      ) : (
        <img
          src={image.sourceUrl}
          alt={image.altText}
          className={`${classes} pointer-events-none`}
        />
      )}
    </>
  )
}

export const renderBackgroundImage = (image, classes) => {
  // Checks image exists, before trying to find Sharp image
  if (!image) return false
  const { fluid } = (image.localFile || {}).childImageSharp || {}

  // Returns gatsby-background-image component with fluid Sharp image and classes
  return (
    <BackgroundImage
      Tag="section"
      fluid={fluid}
      className={`${classes}`}
      alt=""
    />
  )
}
